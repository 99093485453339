<template>
	<div class="container infoPage ">
		<InformationMenu></InformationMenu>
		<div class="content">
			<h1 class="title heading mrb-10">Гарантия низкой цены</h1>
			<h3 class="header mrt-0 mrb-4">Как это работает</h3>
			<h4 class="header mrt-0 mrb-3">Если Вы только планируете совершить покупку</h4>
			<p class="text_base1 black_90 mrt-0 mrb-0">
				Если Вы только планируете совершить покупку и нашли желаемый товар дешевле в другом магазине из
				перечисленных в правилах акции, мы предоставим Вам товар по цене конкурента.
			</p>
			<div class="warning blue mrt-10 mrb-20">
				<p class="text_base1_bold black_90 mrt-0 mrb-0">Список магазинов, относящихся к конкурентам</p>
				<p class="text_base1 black_90 mrt-1 mrb-0">Технодом, Сулпак, Мечта, Алсер, Эврика</p>
			</div>


			<div class="collapsible">
				<h3 class="header mrt-0 mrb-0">Условия акции</h3>

				<h4 class="header mrb-4 mrt-3">Реализация товара по цене конкурента возможна при соблюдении следующих
					условий:</h4>
				<ul class="numbered-list blue">
					<li class="text_base1">Магазин конкурента должен быть из числа магазинов в утвержденном списке
						конкурентов в городе
						обращения;
					</li>
					<li class="text_base1">Предложения фирменного иагазина Samsung и конкурента идентичны: наименование
						товара, модель,
						комплектация полностью совпадают;
					</li>
					<li class="text_base1">В акции не участвуют товарные позиции, к которым в фирменном магазине Samsung
						идет в комплекте
						подарок, сертификат или другое дополнение;
					</li>
					<li class="text_base1 collapsed">Сравниваемый товар имеется в наличии у конкурента в городе обращения,
						для
						розничных
						иинтернет-магазинов это означает физическое наличие товара в торговой точке конкурента в городе
						обращения;
					</li>
					<li class="text_base1 collapsed">Сравнение идет с оффлайн и онлайн ценами. Подтверждением цены
						конкурента являются:
						официальный сайт
						конкурента (с учётом города) действующий на момент обращения в городеобращения;
					</li>
					<li class="text_base1 collapsed">При частичном или полном возврате суммы за покупку ранее начисленные
						бонусы
						списываются в полном
						размере.</li>
				</ul>
				<div class="list-wrap mrt-10 collapsed">
					<h4 class="header mrt-0 mrb-3">Проверка цены осуществляется директором магазина согласно инструкции:
					</h4>
					<ul class="numbered-list blue">
						<li class="text_base1">
							Проверка цены на сайте конкурента, скриншот полного экрана с сайта конкурента, на котором будут
							зафиксированы дата и время;
						</li>
						<li class="text_base1">
							Звонок в call-центр конкурента с целью проверки физического наличия модели товара в магазине
							конкурента в городе обращения;
						</li>
						<li class="text_base1">
							После проведения проверки цен, директор магазина / супервайзера магазина / оператор call-центра
							отпускает товар по цене конкурента.
						</li>
					</ul>
				</div>
				<div class="list-wrap mrt-10 collapsed">
					<h4 class="header mrt-0 mrb-3">При сравнении учитываются только цены:</h4>
					<ul class="numbered-list blue">
						<li class="text_base1">
							Относящиеся к городу обращения;
						</li>
						<li class="text_base1">
							Без учета применения индивидуальных скидок, специальных скидочных средств (сертификаты,
							подарочные
							карты и т.п.), скидок на комплекты, скидок по кредитным программам;
						</li>
						<li class="text_base1">
							Относящиеся к кондиционному, новому товару (товар и упаковка не имеют дефектов, полная
							комплектация);
						</li>
						<li class="text_base1">
							Не являющиеся ценовой или типографской ошибкой;
						</li>
						<li class="text_base1">
							В акции принимают участие товары, полностью или частично оплаченные в кредит;
						</li>
						<li class="text_base1">
							В акции участвуют только физические лица;
						</li>
						<li class="text_base1">
							Фирменный магазин Samsung входит в список сравнения цен по акции;
						</li>
						<li class="text_base1">
							К акционной цене не предусматриваются начисления Smart бонусов и дополнительных скидок (купоны и
							т.д.);
						</li>
						<li class="text_base1">
							Оплату товара можно провести Smart бонусами и подарочными картами.
						</li>
					</ul>
				</div>
				<!-- <div class="list-wrap mrt-10 collapsed">
					<h4 class="header mrt-0 mrb-3">Возврат 110% разницы в цене на ранее приобретённый товар, в случае
						обнаружения
						аналогичного товара в магазине конкурента возможен при соблюдении следующих условий:</h4>
					<ul class="numbered-list blue">
						<li class="text_base1">
							С момента покупки товара прошло не более 14 дней, не считая день покупки;
						</li>
						<li class="text_base1">
							При наличии кассового чека фирменного магазина Samsung;
						</li>
						<li class="text_base1">
							Возврату подлежит 110% разницы между стоимостью товара в фирменном магазине Samsung и стоимостью
							товара в магазине конкурента. Разница в цене начисляется в виде бонусов на карту Smart Bonus
							клиента;
						</li>
						<li class="text_base1">
							В акции принимают участие товары, полностью или частично оплаченные в кредит;
						</li>
						<li class="text_base1">
							В акции не участвуют товарные позиции, к которым в фирменном магазине Samsung идет в комплекте
							подарок, сертификат, подарочный купон;
						</li>
						<li class="text_base1">
							В акции участвуют только физические лица;
						</li>
					</ul>
				</div> -->
				<div class="list-wrap mrt-10 collapsed">
					<h4 class="header mrt-0 mrb-3">Ограничения по участию в акции Гарантия низкой цены:</h4>
					<ul class="numbered-list blue">
						<li class="text_base1">
							В случае если цена на ценнике, сделанном в магазине конкурента, отличается более чем на 10%, от
							цены, указанной на сайте компании конкурента, директор фирменного магазина Samsung вправе
							отказать в
							обращении по акции Гарантия низкой цены;
						</li>
						<li class="text_base1">
							Директор магазина вправе отказать в обращении по акции, если отсутствует возможность доподлинно
							убедиться в идентичности предложения конкурента или актуальности его цены;
						</li>
						<li class="text_base1">
							Бонусы активируются через 14 дней со дня начисления (не считая день начисления).
						</li>
					</ul>
				</div>
			</div>

			<a class="btn" @click="toggleCollapsible">Узнать подробнее</a>
		</div>
	</div>
	<div class="container mgt-35">
		<careServiceWidget />
	</div>
</template>
<script>
import InformationMenu from '@/components/InformationMenu.vue'
import careServiceWidget from '@/components/careServiceWidget.vue';

export default {
	components: {
		InformationMenu,
		careServiceWidget
	},
	name: 'garantiePage',
	props: {
	},
	methods: {
		toggleCollapsible() {
			const collapsibleItems = document.querySelectorAll('.collapsed');
			collapsibleItems.forEach((item) => {
				item.classList.toggle('expanded');
			});

			const btn = document.querySelector('.btn');
			btn.style.display = 'none';
		},
	}
}
</script>
<style scoped>
.collapsed {
	display: none;
	max-height: 0;
	overflow: hidden;
	transition: max-height 0.5s ease-in-out;
}

.expanded {
	display: block;
	max-height: none;
	overflow: hidden;
	transition: max-height 0.5s ease-in-out;
}

.numbered-list li::before {
	position: absolute;
	visibility: visible;
	backface-visibility: visible;
	height: 100%;
	margin-left: -20px !important;
}

.infoPage .numbered-list {
	padding-left: 0;
}

.numbered-list li {
	padding-left: 36px;
}

.infoPage .btn {
	font-size: 16px;
	font-style: normal;
	font-weight: 600;
	line-height: 140%;
	padding: 20px 28px;
	background: #eaedff;
	border-radius: 12px;
	margin: 0;
	color: #2b47da;
	transition: all .3s ease;
}

.infoPage .btn:hover {

	background: #E5E8FA;
	transition: all .3s ease;
}

.infoPage .warning.blue {
	padding: 24px;
	border-radius: 20px;
	background: #eaedff;
	display: flex;
	align-items: flex-start
}

.infoPage .warning.blue:before {
	position: absolute;
	top: unset !important;
	margin-left: -36px !important;
}

.warning.blue.mrt-10.mrb-20 {
	position: relative;
	flex-flow: column;
	padding-left: 60px;
	padding-bottom: 24px;
	margin-bottom: 90px;
}

.infoPage .warning.blue::before {
	top: unset !important;
	margin-left: -36px !important;
}

@media(max-width:900px) {
	.infoPage .warning.blue {
		font-size: 16px
	}

	.infoPage .btn {
		font-size: 12px;
		font-style: normal;
		font-weight: 600;
		padding: 12px 16px;
	}
}

.infoPage .warning.blue:before {
	content: url("data:image/svg+xml;charset=utf-8,%3Csvg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg stroke='%232B47DA' stroke-width='2'%3E%3Ccircle cx='12' cy='12' r='11'/%3E%3Cpath d='M12 9.6V18M12 6.4V8'/%3E%3C/g%3E%3C/svg%3E");
	height: 24px;
	margin-right: 12px;
	top: 3px
}

.collapsible>.numbered-list.blue {
	margin-bottom: 24px;
}
</style>
<style>
.infoPage {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	margin-block: 117px 140px !important;
}

.content {
	flex-basis: calc(100% - 365px);
}

@media (max-width: 900px) {
	.infoPage {
		margin-block: 100px 0px !important;
	}

	h1.heading {
		margin-bottom: 40px !important;
	}

	#app>div>.container.infoPage {
		padding-inline: 10px !important;
	}

	.content {
		flex-basis: 100%;
	}
}
</style>
<style scoped lang="scss">
.container.infoPage {
	min-height: 39.5vh;
}
</style>

<style lang="scss">
@media (max-width:900px) {
	#app>div>.container.mgt-35 {
		padding-left: 20px !important;
		max-width: unset;
		width: 100%;
		padding-right: 20px !important;
	}
}
</style>